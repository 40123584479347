import React from 'react'
import Dashboard from '../../dashboard/Dashboard'
import { Box, Typography } from '@mui/material'
import CustomizedDataGrid from '../../dashboard/components/CustomizedDataGrid'
import { feedbacksTableColumns } from '../../utils/table-columns'

function Feedback() {
    return (
        <Dashboard headerTitle='Feedback'>
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                    Feedback
                </Typography>
                <CustomizedDataGrid columns={feedbacksTableColumns} />
            </Box>
        </Dashboard>
    )
}

export default Feedback