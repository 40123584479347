import React from 'react'
import CustomizedDataGrid from '../../../dashboard/components/CustomizedDataGrid'
import FormDialog from '../../../components/FormDialog'
import { CREATE } from '../../../utils/constant'
import { districtFormFields } from '../../../utils/form-fields'
import { districtsTableColumns } from '../../../utils/table-columns'

function Districts({
    openCreateDistrictDialog,
    setOpenCreateDistrictDialog
}) {
    const values = [
        {
            id: 0,
            district: "",
        },
    ];

    return (
        <>
            {openCreateDistrictDialog && (
                <FormDialog
                    open={openCreateDistrictDialog}
                    handleClose={() => setOpenCreateDistrictDialog(false)}
                    dialogTitle={"District"}
                    action={CREATE}
                    fields={districtFormFields}
                    values={values}
                    url={""}
                />
            )}
            <CustomizedDataGrid columns={districtsTableColumns} />
        </>
    )
}

export default Districts