export const rowDirectionSpaceBetween = {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    width: '100%',
    justifyContent: 'space-between',
    alignItems: { xs: 'start', md: 'center' },
    gap: 4,
    overflow: 'auto',
    mb: 1
};

export const rowDirection = {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    width: '100%',
    alignItems: { xs: 'start', md: 'center' },
    gap: 2,
    overflow: 'auto',
    my: 2
};