import React from 'react';
import Dashboard from '../../dashboard/Dashboard';
import MainGrid from '../../dashboard/components/MainGrid';

function Home() {
    return (
        <Dashboard headerTitle='Home'>
            <MainGrid />
        </Dashboard>
    )
}

export default Home;