import React, { useState } from 'react'
import Dashboard from '../../dashboard/Dashboard'
import { Box, Button, Typography } from '@mui/material'
import CustomTabs from '../../components/CustomTabs';
import { rowDirectionSpaceBetween } from '../../utils/styles';
import { Add } from '@mui/icons-material';
import ParcelTypes from './sections/ParcelTypes';
import Regions from './sections/Regions';
import Districts from './sections/Districts';
import Wards from './sections/Wards';

const orderStatus = [
    { label: "Parcel types", value: "Parcel-types" },
    { label: "Regions", value: "Regions" },
    { label: "Districts", value: "Districts" },
    { label: "Wards", value: "Wards" },
];

function Settings() {
    const [currentTab, setCurrentTab] = useState(orderStatus[0].value);
    const [openCreateParcelTypeDialog, setOpenCreateParcelTypeDialog] = React.useState(false);
    const [openCreateRegionDialog, setOpenCreateRegionDialog] = React.useState(false);
    const [openCreateDistrictDialog, setOpenCreateDistrictDialog] = React.useState(false);
    const [openCreateWardDialog, setOpenCreateWardDialog] = React.useState(false);

    const onClickTab = (item) => {
        setCurrentTab(item.value);
    }

    const handleOpenDialog = () => {
        if (currentTab === orderStatus[0].value) {
            setOpenCreateParcelTypeDialog(true);
        }
        if (currentTab === orderStatus[1].value) {
            setOpenCreateRegionDialog(true);
        }
        if (currentTab === orderStatus[2].value) {
            setOpenCreateDistrictDialog(true);
        }
        if (currentTab === orderStatus[3].value) {
            setOpenCreateWardDialog(true);
        }
    }

    return (
        <Dashboard headerTitle='Settins'>
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                    Settings
                </Typography>
                <Box
                    sx={rowDirectionSpaceBetween}
                >
                    <CustomTabs
                        tabs={orderStatus}
                        currectTab={currentTab}
                        onClick={onClickTab}
                    />
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<Add />}
                        onClick={handleOpenDialog}
                    >
                        Add
                    </Button>
                </Box>
                {currentTab === orderStatus[0].value &&
                    <ParcelTypes
                        openCreateParcelTypeDialog={openCreateParcelTypeDialog}
                        setOpenCreateParcelTypeDialog={setOpenCreateParcelTypeDialog}
                    />
                }
                {currentTab === orderStatus[1].value &&
                    <Regions
                        openCreateRegionDialog={openCreateRegionDialog}
                        setOpenCreateRegionDialog={setOpenCreateRegionDialog}
                    />
                }
                {currentTab === orderStatus[2].value &&
                    <Districts
                        openCreateDistrictDialog={openCreateDistrictDialog}
                        setOpenCreateDistrictDialog={setOpenCreateDistrictDialog}
                    />
                }
                {currentTab === orderStatus[3].value &&
                    <Wards
                        openCreateWardDialog={openCreateWardDialog}
                        setOpenCreateWardDialog={setOpenCreateWardDialog}
                    />
                }
            </Box>
        </Dashboard>
    )
}

export default Settings