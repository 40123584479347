export const baseUrl = "https://www.service.smai.co.tz/api/v1/"

//USER MANAGEMENT ROUTES
export const registerUserUrl = `${baseUrl}register/user`
export const getUserUrl = `${baseUrl}get/user`
export const getUsersUrl = `${baseUrl}get/users`
export const loginByEmailPasswordUrl = `${baseUrl}login/user`

//PARCEL TYPES MANAGEMENT ROUTES
export const createParcelTypeUrl = `${baseUrl}create/parcel/type`
export const getAllParcelTypesByPaginationUrl = `${baseUrl}get/all/parcel/types/by/pagination`

//ORDERS MANAGEMENT ROUTES
export const getAllOrdersByPaginationUrl = `${baseUrl}get/all/orders/by/pagination`