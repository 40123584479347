import React from 'react'
import { Box, Chip } from '@mui/material'

function CustomTabs({ tabs, currectTab, onClick }) {

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 3,
                overflow: 'auto',
            }}
        >
            {tabs.map((item, index) => {
                return (
                    <Chip
                        key={index}
                        onClick={() => {
                            onClick(item);
                        }}
                        size="medium"
                        label={item.label}
                        sx={{
                            ...(item.value !== currectTab && {
                                backgroundColor: 'transparent',
                                border: 'none',
                            })
                        }}
                    />
                );
            })}
        </Box>
    )
}

export default CustomTabs