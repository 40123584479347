import React, { useEffect, useState } from 'react'
import CustomizedDataGrid from '../../../dashboard/components/CustomizedDataGrid'
import FormDialog from '../../../components/FormDialog'
import { CREATE } from '../../../utils/constant'
import { parcelTypeFormFields } from '../../../utils/form-fields'
import { parcelTypesTableColumns } from '../../../utils/table-columns'
import { authPostRequest } from '../../../services/api-service'
import { createParcelTypeUrl, getAllParcelTypesByPaginationUrl } from '../../../services/urls'
import { useCallback } from 'react'
import { useAuth } from '../../../hooks/use-auth'
import { Box, Button } from '@mui/material'
import { rowDirection } from '../../../utils/styles'

function ParcelTypes({
    openCreateParcelTypeDialog,
    setOpenCreateParcelTypeDialog
}) {
    const auth = useAuth()
    const [parcelDetails, setParcelDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const values = [
        {
            id: 0,
            parcel_type: "",
        },
    ];
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    const getParcelTypes = useCallback(
        () => {
            setLoading(true)
            authPostRequest(
                getAllParcelTypesByPaginationUrl,
                {
                    query: auth.query,
                    sort: "id desc",
                    page: paginationModel.page + 1,
                },
                (data) => {
                    setParcelDetails(data)
                    setLoading(false)
                },
                (error) => {
                    setLoading(false)
                }
            )
        }, [auth.query, paginationModel.page]
    )

    useEffect(() => {
        getParcelTypes()
    }, [getParcelTypes])

    return (
        <>
            {openCreateParcelTypeDialog && (
                <FormDialog
                    open={openCreateParcelTypeDialog}
                    handleClose={() => {
                        getParcelTypes()
                        setOpenCreateParcelTypeDialog(false)
                    }}
                    dialogTitle={"Parcel Type"}
                    action={CREATE}
                    fields={parcelTypeFormFields}
                    values={values}
                    url={createParcelTypeUrl}
                />
            )}
            {rowSelectionModel.length > 0 &&
                <Box
                    sx={rowDirection}
                >
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            console.log(rowSelectionModel)
                        }}
                    >
                        View
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            console.log(rowSelectionModel)
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            console.log(rowSelectionModel)
                        }}
                    >
                        Delete
                    </Button>
                </Box>
            }
            <CustomizedDataGrid
                loading={loading}
                columns={parcelTypesTableColumns}
                data={parcelDetails}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
            />
        </>
    )
}

export default ParcelTypes