import React from 'react'
import CustomizedDataGrid from '../../../dashboard/components/CustomizedDataGrid'
import FormDialog from '../../../components/FormDialog'
import { CREATE } from '../../../utils/constant'
import { wardFormFields } from '../../../utils/form-fields'
import { wardsTableColumns } from '../../../utils/table-columns'

function Wards({
    openCreateWardDialog,
    setOpenCreateWardDialog
}) {
    const values = [
        {
            id: 0,
            ward: "",
        },
    ];

    return (
        <>
            {openCreateWardDialog && (
                <FormDialog
                    open={openCreateWardDialog}
                    handleClose={() => setOpenCreateWardDialog(false)}
                    dialogTitle={"Ward"}
                    action={CREATE}
                    fields={wardFormFields}
                    values={values}
                    url={""}
                />
            )}
            <CustomizedDataGrid columns={wardsTableColumns} />
        </>
    )
}

export default Wards