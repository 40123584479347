import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../../dashboard/Dashboard'
import { Box, Button, Typography } from '@mui/material'
import CustomizedDataGrid from '../../dashboard/components/CustomizedDataGrid'
import CustomTabs from '../../components/CustomTabs'
import FormDialog from '../../components/FormDialog'
import { Add } from '@mui/icons-material'
import { rowDirection, rowDirectionSpaceBetween } from '../../utils/styles'
import { userFormFields } from '../../utils/form-fields'
import { CREATE } from '../../utils/constant'
import { usersTableColumns } from '../../utils/table-columns'
import { authPostRequest } from '../../services/api-service'
import { getUsersUrl, registerUserUrl } from '../../services/urls'
import { useAuth } from '../../hooks/use-auth'

const roles = [
    { label: "All", value: "" },
    { label: "Users", value: "NORMAL_USER" },
    { label: "Agents", value: "AGENT" },
    { label: "Drivers", value: "DRIVER" },
    { label: "Admins", value: "SUPER_ADMIN" },
];

function Users() {
    const auth = useAuth()
    const [usersDetails, setUsersDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(roles[0].value);
    const [openCreateUserDialog, setOpenCreateUserDialog] = React.useState(false);
    const values = [
        {
            id: 0,
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            gender: "",
            password: "",
            role: currentTab,
        },
    ];
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    const onClickTab = (item) => {
        setCurrentTab(item.value);
    }

    const getUsers = useCallback(
        () => {
            setLoading(true)
            authPostRequest(
                getUsersUrl,
                {
                    query: auth.query,
                    role: currentTab,
                    sort: "id desc",
                    page: paginationModel.page + 1,
                },
                (data) => {
                    setUsersDetails(data)
                    setLoading(false)
                },
                (error) => {
                    setUsersDetails({})
                    setLoading(false)
                }
            )
        }, [auth.query, currentTab, paginationModel.page]
    )

    useEffect(() => {
        getUsers()
    }, [getUsers])

    return (
        <Dashboard headerTitle='Users'>
            {openCreateUserDialog && (
                <FormDialog
                    open={openCreateUserDialog}
                    handleClose={() => {
                        getUsers()
                        setOpenCreateUserDialog(false)
                    }}
                    dialogTitle={"User"}
                    action={CREATE}
                    fields={userFormFields}
                    values={values}
                    url={registerUserUrl}
                />
            )}
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                    Users
                </Typography>
                <Box
                    sx={rowDirectionSpaceBetween}
                >
                    <CustomTabs
                        tabs={roles}
                        currectTab={currentTab}
                        onClick={onClickTab}
                    />
                    {currentTab !== roles[0].value &&
                        currentTab !== roles[1].value &&
                        <Button
                            variant='contained'
                            size='small'
                            startIcon={<Add />}
                            onClick={() => {
                                setOpenCreateUserDialog(true);
                            }}
                        >
                            Add
                        </Button>
                    }
                </Box>
                {rowSelectionModel.length > 0 &&
                    <Box
                        sx={rowDirection}
                    >
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                console.log(rowSelectionModel)
                            }}
                        >
                            View
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                console.log(rowSelectionModel)
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                console.log(rowSelectionModel)
                            }}
                        >
                            Delete
                        </Button>
                    </Box>
                }
                <CustomizedDataGrid
                    loading={loading}
                    columns={usersTableColumns}
                    data={usersDetails}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    rowSelectionModel={rowSelectionModel}
                    setRowSelectionModel={setRowSelectionModel}
                />
            </Box>
        </Dashboard>
    )
}

export default Users